<template>
  <div class="password">
    <el-breadcrumb class="breadCrumb"
                   separator="/">
      <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      <el-breadcrumb-item>账号安全</el-breadcrumb-item>
      <el-breadcrumb-item>密码管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!--<el-button class="idBtn" type="primary">获取验证码</el-button>:text="delayTime<60?'重新发送':'获取验证码1'"-->
    <div class="bgWhite mt20">
      <div class="pwBox">
        <el-form class="cardInfo mt20"
                 :rules="rules"
                 ref="form"
                 :model="form"
                 label-width="110px">
          <el-form-item label="手机号码："
                        prop="phoneNum">
            <el-input v-model="form.phoneNum"
                      class="input450"
                      placeholder="请输入手机号码"
                      disabled></el-input>
          </el-form-item>
          <el-form-item label="验证码："
                        prop="phoneCode">
            <el-input style="width: 320px"
                      v-model="form.phoneCode"
                      class="input450"
                      placeholder="请输入短信验证码"></el-input>
            <DelayButton :num="getPhone"
                         :canClickRun='canClickRun'
                         class="idBtn"
                         style="width: 120px"
                         ref="form" />
          </el-form-item>
          <el-form-item label="新登录密码："
                        prop="newPassWord">
            <el-input v-model="form.newPassWord"
                      class="input450"
                      Onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
                      placeholder="请输入新登录密码（6-20位字母数字组合）"></el-input>
          </el-form-item>
          <el-form-item label="确认密码："
                        prop="rePassword">
            <el-input v-model="form.rePassword"
                      class="input450"
                      Onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
                      placeholder="请重复密码（6-20位字母数字组合）"></el-input>
          </el-form-item>
          <div class="formBtn mt30">
            <el-button class="btn submit"
                       @click="submitForm('form')"
                       size="small"
                       type="primary"><i></i>提 交</el-button>
          </div>
        </el-form>

      </div>
    </div>
  </div>
</template>

<script>
// import DelayButton from "@/views/Ticket/childrenCpn/DelayButton";
import DelayButton from "@/views/MSME/auth/accountSafe/DelayButton";

export default {
  name: "Password",
  components: {
    DelayButton: DelayButton,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      let reg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+$/;
      if (value.length > 20 || value.length < 6) {
        callback(new Error("6-20个字符"));
      } else if (reg.test(value)) {
        callback();
      } else {
        callback(new Error("必须为数字和字母组合"));
      }
      // const val = e.target;
      //
      // val.value = val.value.replace(/[^\u4E00-\u9FA5]/g, ''); // 清除除了中文以外的输入的字符
      //
      // this.name = val.value;
    };
    return {
      getPhone: this.$store.state.getUser, //发送短信的手机号
      canClickRun: true,
      form: {
        phoneNum:
          this.$store.state.getUser.substr(0, 3) +
          "****" +
          this.$store.state.getUser.substr(7), //手机号登录回显
      },
      rules: {
        phoneNum: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          // {
          //   type: 'number',
          //   message: '手机号格式不正确',
          //   trigger: 'blur',
          //   transform(value) {
          //     var phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
          //     if (!phonereg.test(value)) {
          //       return false
          //     } else {
          //       return Number(value)
          //     }
          //   }
          // }
        ],
        phoneCode: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ],
        newPassWord: [
          {
            required: true,
            message: "请输入新登录密码（6-20位字母数字组合）",
            trigger: "blur",
          },
          { validator: validatePass, trigger: "blur" },
        ],
        rePassword: [
          {
            required: true,
            message: "请重复密码（6-20位字母数字组合）",
            trigger: "blur",
          },
          { validator: validatePass, trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    // this.$nextTick(function () {
    //   this.$on('DelayButton', function () {
    //     console.log('我是子组件方法');
    //   });
    // });
  },
  created() {
    console.log(this.$store.state.getUser);
  },
  methods: {
    //提交
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.form.newPassWord != this.form.rePassword) {
            return this.$message.warning("请确认两次输入密码一致");
          }
          let obj = {
            phoneNum: this.getPhone,
            // phoneNum: this.form.phoneNum,
            phoneCode: this.form.phoneCode,
            newPassWord: this.form.newPassWord,
          };
          const { data: res } = await this.$auth.post(
            "/cpiaoju-auth/accountSecurity/updateLoginPassword",
            this.$qs.stringify(obj)
          );
          if (res.code == 200) {
            this.form.phoneCode = "";
            this.form.newPassWord = "";
            this.form.rePassword = "";
            this.canClickRun = true;
            this.$message.success(res.msg);
          } else {
            this.canClickRun = true;

            this.$message.error(res.msg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // getChild() {
    //   console.log(this.$refs.DelayButton.text, '11')
    //   let text = this.$refs.DelayButton.text;
    //   console.log(text)
    // }
  },
};
</script>

<style lang="less" scoped>
.password {
  .bgWhite {
    height: 604px;
  }

  .pwBox {
    width: 600px;
    margin: 120px auto 0;

    .idBtn {
      width: 100px;
      padding: 12px 2px;
    }
  }
}
</style>