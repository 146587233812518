<template>
  <el-button :type="type"
             :disabled="!canClickRun"
             @click="clickBtn">
    {{canClickRun ? text : `${text1}(${delayTime}s)`}}
  </el-button>
</template>

<script>
export default {
  name: "DelayButton",
  props: {
    text: {
      type: String,
      default: "发送验证码",
    },
    text1: {
      type: String,
      default: "重新发送",
    },
    delayTime: {
      type: Number,
      default: 60,
    },
    type: {
      type: String,
      default: "primary",
    },
    num: {
      type: String,
      default: "",
    },
    canClickRun: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      canClickRun: true,
    };
  },
  methods: {
    async clickBtn() {
      // this.$refs["form"].validateField("phoneNum",async (valid) => {
      // 	if (valid) {
      if (this.num !== "" || null) {
        const { data: res } = await this.$auth.get(
          "/cpiaoju-auth/login/setPhoneCode",
          {
            params: {
              tel: this.num,
            },
          }
        );
        if (res.code == 200) {
          console.log("111");
          this.canClickRun = false;
          let timer = setInterval(() => {
            this.delayTime -= 1;
            if (this.delayTime <= 0) {
              clearInterval(timer);
              this.canClickRun = true;
              this.delayTime = 60;
            }
          }, 1000);
        }
      } else {
        this.$message.error("手机号不能为空！");
      }
      // }
      // })
    },
  },
};
</script>

<style scoped>
</style>